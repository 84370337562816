import { createMuiTheme } from '@material-ui/core/styles'
import { amber, grey } from '@material-ui/core/colors'
// import { dark } from '@material-ui/core/styles/createPalette'

const mainColor = 'white'
const theme = createMuiTheme({
  palette: {
    // type: 'dark',
    primary: { main: amber[500] },
    secondary: { main: '#50066A' },
    tertiary: { main: '#95c11f' },
  },
  overrides: {
    MuiButton: {
      root: {
        color: mainColor,
      },
    },
    MuiAppBar: { colorPrimary: { color: mainColor } },
    MuiDrawer: {
      paper: {
        backgroundColor: grey[800],
      },
    },
    MuiListItem: {
      root: {
        // backgroundColor: grey[800],
        color: grey[50],
      },
    },
  },
})

export { theme }
