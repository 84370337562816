import React from 'react'
import L from 'leaflet'
// import { Link } from 'react-router-dom'

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import classes from '*.module.css';

const mapBackground = '/images/magurele-puzzle-1920.png'
const defaultMapBackgroundW = 1920
const defaultMapBackgroundH = 1385
let mapBackgroundW = 1920
let mapBackgroundH = 1385
let markerModifier = 1
let markerModifierPosition = 1
let mapW = window.innerWidth
const markerFallbackIcon = '/images/markers/marker-1.png'
const markerFallbackIconShadow = ''
const markers = [
  {
    x: 1060,
    y: 1050,
    title: '<strong>Şcoala de Vară 2018</strong>',
    iconUrl: '/images/markers/air-balloon.png',
    shadowUrl: '/images/markers/air-balloon-shadow.png',
    iconSize: [104, 211],
    iconAnchor: [64, 64],
    shadowSize: [127, 128],
    shadowAnchor: [54, 34],
    popupAnchor: [-13, -69],
  },
  {
    x: 1410,
    y: 625,
    title: '<strong>Şcoala de Vară 2019</strong>',
    iconUrl: '/images/markers/scdv-2019.png',
    iconSize: [81, 178],
    iconAnchor: [81, 178],
    popupAnchor: [-43, -185],
  },
  {
    x: 880,
    y: 780,
    title: '<strong>Centrul dedicat activităţilor STEM</strong>',
    iconUrl: '/images/markers/ifa.png',
    iconSize: [128, 128],
    iconAnchor: [64, 64],
    popupAnchor: [0, -64],
  },
  {
    x: 1610,
    y: 378,
    title: '<strong>Festivaluri de Ştiinţă și Expoziţii</strong>',
    iconUrl: '/images/markers/expo.png',
    iconSize: [109, 108],
    iconAnchor: [55, 35],
    popupAnchor: [-13, -59],
  },
  {
    x: 580,
    y: 895,
    title: '<strong>Universitatea din Bucureşti – Facultatea de Fizică</strong>',
    iconUrl: '/images/markers/ffiz-ub.png',
    iconSize: [128, 128],
    iconAnchor: [64, 64],
    popupAnchor: [0, -64],
  },
  {
    x: 1240,
    y: 1090,
    title: '<strong>Extreme Light Infrastructure - Nuclear Physics</strong>',
    iconUrl: '/images/markers/eli-np.png',
    iconSize: [128, 128],
    iconAnchor: [64, 64],
    popupAnchor: [0, -64],
  },
]

if (window.innerWidth > window.innerHeight) {
  // console.log('vW')
  mapBackgroundH = window.innerHeight - 64 * 2
  mapBackgroundW = (defaultMapBackgroundW * mapBackgroundH) / defaultMapBackgroundH
  markerModifierPosition = mapBackgroundH / defaultMapBackgroundH
  markerModifier = mapBackgroundH / defaultMapBackgroundH
} else {
  // console.log('vH')
  mapBackgroundW = window.innerWidth
  mapBackgroundH = (defaultMapBackgroundH * mapBackgroundW) / defaultMapBackgroundW
  markerModifierPosition = mapBackgroundW / defaultMapBackgroundW
  markerModifier = mapBackgroundH / defaultMapBackgroundH
}
if (markerModifier < 0.5) {markerModifier = 0.5}


function TransitionLeft(props) {
  return <Slide {...props} direction="left" />;
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />;
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const styles = theme => ({
  dialogContent: {
    '& h1.MuiTypography-root': {
      lineHeight: 1.2
    },
    [theme.breakpoints.down('md')]: {
      '& h1.MuiTypography-root': {
        fontSize: '2.8rem'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& h1.MuiTypography-root': {
        marginBottom: '1rem',
        fontSize: '2rem',
      }
    },
  },
})

export default withWidth()(
  withStyles(styles)(
    class Map extends React.Component {
  constructor() {
    super()
    this.updateDimensions = this.updateDimensions.bind(this)
    this.state = {
      viewportW: window.innerWidth,
      viewportH: window.innerHeight,
      'snackOpened': true,
      'transition': TransitionDown,
  }
  }

  updateDimensions = () => {
    this.setState({
      viewportW: window.innerWidth,
      viewportH: window.innerHeight,
    })
  }

  map() {
    const yx = L.latLng
    const xy = function(x, y) {
      if (L.Util.isArray(x)) {
        return yx(x[1], x[0])
      }
      return yx(y, x)
    }

    // const bounds = [xy(0, 0), xy(this.state.viewportW, this.state.viewportH)]
    const bounds = [xy(0, -50), xy(mapBackgroundW, mapBackgroundH)]

    this.map = L.map('map', {
      crs: L.CRS.Simple,
      // renderer: L.svg(),
      zoom: 1,
      minZoom: 0,
      maxZoom: 2,
      // maxBounds: [-550,-100],
      // zoomControl: false
    })
    this.map.setMaxBounds(bounds)
    this.map.on('drag', function() {
      this.panInsideBounds(bounds, { animate: false })
    })

    this.map.zoomControl.setPosition('bottomright')
    L.imageOverlay(mapBackground, bounds).addTo(this.map)

    markers.map((item, index) => {
      // console.log(item.iconSize ? item.iconSize[0] : '')
      // console.log(markerModifier)
      let marker = L.marker(
        // xy((item.x * mapBackgroundW) / defaultMapBackgroundW, (item.y * mapBackgroundH) / defaultMapBackgroundH),
        xy(item.x * markerModifierPosition, item.y * markerModifierPosition),
        {
          icon: L.icon({
            iconUrl: item.iconUrl ? item.iconUrl : markerFallbackIcon,
            shadowUrl: item.shadowUrl ? item.shadowUrl : markerFallbackIconShadow,
            iconSize: item.iconSize
              ? item.iconSize.map(x => x * markerModifier)
              : [48, 48].map(x => x * markerModifier),
            shadowSize: item.shadowSize
              ? item.shadowSize.map(x => x * markerModifier)
              : [50, 64].map(x => x * markerModifier),
            iconAnchor: item.iconAnchor
              ? item.iconAnchor.map(x => (x / 2) * (markerModifierPosition + markerModifier))
              : [24, 24].map(x => (x / 2) * (markerModifierPosition + markerModifier)), // point of the icon which will correspond to marker's location
            shadowAnchor: item.shadowAnchor
              ? item.shadowAnchor.map(x => (x / 2) * (markerModifierPosition + markerModifier))
              : [4, 62].map(x => (x / 2) * (markerModifierPosition + markerModifier)), // the same for the shadow
            popupAnchor: item.popupAnchor
              ? item.popupAnchor.map(x => x * markerModifierPosition)
              : [0, -30].map(x => x * markerModifierPosition), // point from which the popup should open relative to the iconAnchor
          }),
        },
      )
        .addTo(this.map)
        .bindPopup(item.title, {
          keepInView: true,
          closeButton: true,
          closeOnEscapeKey: true,
        })
    })

    let cM = [],
      popON = false

    this.map
      .setView(xy(mapBackgroundW / 2, mapBackgroundH / 2), 0)
      .on('popupopen', function(centerMarker) {
        cM = centerMarker.popup._latlng
        popON = true
        // console.log(cM["lat"], cM["lng"]);
        this.setView(xy(cM['lng'], cM['lat']), 1)
        // console.log(
        //   ':: popupopen' +
        //     '\n   zoom: ' +
        //     this.getZoom() +
        //     '\n   mark: ' +
        //     centerMarker.popup._latlng +
        //     '\n   popON: ' +
        //     popON,
        // )
      })
      .on('popupclose', function(centerMarker) {
        if (!popON) {this.setView(xy(0, 0), 0)}
        popON = false
        // console.log(':: popupclose' + '\n   zoom: ' + this.getZoom() + '\n   popON: ' + popON)
      })
      .on('zoomend', function(centerMarker) {
        popON ? this.setView(xy(cM['lng'], cM['lat']), 1) : this.setView(xy(0, 0), 0)
        // this.setView(xy(cM["lng"], cM["lat"]), 1);
      })
      .on('click', function(centerMarker) {
        popON ? this.setView(xy(cM['lng'], cM['lat']), 1) : this.setView(xy(0, 0), 0)
        // console.log(':: mapClick' + '\n   zoom: ' + this.getZoom() + '\n   popON: ' + popON)
      })
  }

  componentDidMount() {
    this.map()
    window.addEventListener('resize', this.updateDimensions)
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  render() {
    // console.log(this.props)
    const {classes} = this.props
    const handleClose = () => {
      this.setState({'snackOpened':false});
    };
    const handleVisit = () => {
      window.open('https://scicommtoolbox.ro/?pID=005d48582f', '_blank');
    };

    return (
      <React.Fragment>
        {this.props.showdialog === 1 && 
        <Dialog
          fullScreen={isWidthUp('sm', this.props.width) ? false : true}
          // key={key}
          {...this.props}
          open={this.state.snackOpened}
          // TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className={classes.dialogContent}>
            <DialogContentText component="div" id="alert-dialog-slide-description" dangerouslySetInnerHTML={{ __html: this.props.content.text }} style={{ marginBottom: 0 }} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} size={isWidthUp('sm', this.props.width) ? 'large' : 'small'} color="secondary">
              Înapoi la site-ul Comunității
            </Button>
            <div style={{flexGrow:1}} />
            <Button onClick={handleVisit} size={isWidthUp('sm', this.props.width) ? 'large' : 'small'} variant='contained' style={{boxShadow:'none',backgroundColor:"#673ab7",color:'white'}}>
              Vizitează site-ul SciCommToolbox.ro
            </Button>
          </DialogActions>
        </Dialog>}

        <div id="map" className="MapContainer" style={{ width: mapW + 'px', height: mapBackgroundH + 64 + 'px' }} />
      </React.Fragment>
    )
  }
}
))