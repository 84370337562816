import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useTransition, animated } from 'react-spring'
// import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
// import Button from '@material-ui/core/Button'
import GoogleSheetsProvider from "react-db-google-sheets";

import { ThemeProvider } from '@material-ui/styles'
import { theme } from './themes/MainTheme'

import componentList from './comps/core/ComponentIterator'
import { siteStructure } from './data/siteStructure'
import * as siteContent from './data/siteContent-MUI'

import MainMenu from './comps/core/MainMenu'

import './leaflet.css'
import './styles.css'

const siteName = 'CEST'
const siteNameFull = 'Comunitatea Educație pentru Științe'
const siteLanguage = 'ro'

export default function App() {
  const location = useLocation()
  // console.log(location)

  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 1, transform: 'translate3d(0,100%,0)', transitionTimingFunction: 'linear' },
    enter: { opacity: 1, transform: 'translate3d(0%,0,0)', transitionTimingFunction: 'linear' },
    leave: { opacity: 1, transform: 'translate3d(0,-100%,0)', transitionTimingFunction: 'linear' },
  })
  return transitions.map(({ item, props, key }) => (
    <React.Fragment key={key}>
      <MainMenu
        position="top"
        siteStructure={siteStructure}
        siteName={siteName}
        siteNameFull={siteNameFull}
        siteLanguage={siteLanguage}
      />
      <animated.div key={key} style={props} className="simple-trans-main">
        <Switch location={item}>
          <Route path="/" exact render={() => <Redirect to={'/' + siteLanguage + '/'} />} />
          {siteStructure.map((item, index) => {
            // console.log(item)
            const ComponentIterator = componentList[item.type]
            return (
              <Route
                path={'/' + siteLanguage + '/' + item.path}
                exact
                render={(props) => (
                  <ComponentIterator
                    {...props}
                    name={item.name}
                    next={item.toNext}
                    background={item.background}
                    siteLanguage={siteLanguage}
                    content={siteContent[item.component]}
                  />
                )}
                key={index}
              />
            )
          })}
        </Switch>
      </animated.div>
      <div className="copyright">
        <small>
          Copyright &copy; <strong>Comunitatea Educație pentru Științe</strong>. Toate drepturile rezervate.
          {/* <span className="footerSeparator" />
          <Button size="small" color="inherit" variant="text" to="/ro/politica-de-confidentialitate" component="Link">
            Politica de confidențialitate
          </Button> */}
        </small>
      </div>
    </React.Fragment>
  ))
}

render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <GoogleSheetsProvider>
        <App />
      </GoogleSheetsProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root'),
)
