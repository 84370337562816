/* eslint-disable import/first */
import React from 'react'
import { NavLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
// import ListItemIcon from "@material-ui/core/ListItemIcon";

import MenuIcon from '@material-ui/icons/Menu'
import HomeIcon from '@material-ui/icons/Home'

const FwNavLink = React.forwardRef((props, ref) => <NavLink {...props} innerRef={ref} />)

const styles = theme => ({
  root: {
    flexGrow: 1,
    top: theme.spacing(2),
    right: theme.spacing(2),
    left: theme.spacing(2),
    width: 'calc(100vw - 16*2px)',
    // backgroundColor: "rgba(0,0,0,.66)",
    // backgroundColor: 'rgba(89, 45, 132,.97)',
    backgroundColor: '#50066A',
  },
  grow: {
    flexGrow: 1,
  },
  CESTLogo: {
    maxHeight: 48,
    marginRight: theme.spacing(2),
  },
  menuButton: {
    marginLeft: theme.spacing(2),
    fontWeight: 400,
  },
  menuButtonHome: {
    textTransform: 'none',
  },
  title: {
    fontWeight: 300,
    color: 'white',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,
      marginLeft: theme.spacing(),
    },
  },
  SwipeableDrawer: {
    '&>.MuiDrawer-paper': {
      backgroundColor: '#50066A',
      [theme.breakpoints.down('sm')]: {
        top: theme.spacing(9),
        width: '224px',
        margin: '0 auto',
        // right: 'auto !important',
      },
      [theme.breakpoints.only('xs')]: {},
    },
  },
  selectedNavLink: {
    color: 'orange',
  },
  selectedMainNavLink: {
    color: theme.palette.primary.main,
  },
  sideList: {
    width: 250,
  },
  fullList: {
    width: 'auto',
    textAlign: 'center',
    '& .MuiListItem-root': {
      textAlign: 'center',
    },
  },
})

export default withWidth()(
  withStyles(styles)(
    class ButtonAppBar extends React.Component {
      constructor(props) {
        super(props)
        this.state = {
          [this.props.position]: false,
        }
      }

      render() {
        const { classes, position, siteLanguage, siteStructure, siteName, siteNameFull } = this.props

        // console.log(this.props)

        const toggleDrawer = (side, open) => event => {
          if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
          }

          this.setState({ ...this.state, [side]: open })
        }

        const drawerList = side => (
          <div
            className={
              ['left', 'right'].includes(this.props.position)
                ? this.props.classes.sideList
                : this.props.classes.fullList
            }
            role="presentation"
            onClick={toggleDrawer(side, false)}
            onKeyDown={toggleDrawer(side, false)}>
            <List
              aria-labelledby="nested-list-subheader"
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {this.state[this.props.position]}
                </ListSubheader>
              }>
              {siteStructure.map((item, index) =>
                item.menu ? (
                  <ListItem
                    link
                    key={index}
                    to={'/' + siteLanguage + '/' + item.path}
                    exact
                    component={NavLink}
                    activeClassName={classes.selectedNavLink}>
                    <ListItemText primary={item.name} />
                  </ListItem>
                ) : null,
              )}
            </List>
          </div>
        )

        if (isWidthUp('md', this.props.width)) {
          return (
            <AppBar position="fixed" className={classes.root}>
              <Toolbar>
                <img src="/images/cest-logo-white.128.png" alt={siteNameFull} className={classes.CESTLogo} />
                <Typography variant="h6" className={classes.title} component={NavLink} to="/">
                  {isWidthUp('lg', this.props.width) ? siteNameFull : siteName}
                </Typography>
                <div className={classes.grow} />
                {siteStructure.map((item, index) =>
                  item.menu ? (
                    index === 0 ? (
                      <Button
                        to={'/' + siteLanguage + '/' + item.path}
                        component={FwNavLink}
                        className={classes.menuButton}
                        // activeClassName={classes.selectedMainNavLink}
                        key={index}>
                        <HomeIcon />
                      </Button>
                    ) : (
                      <Button
                        to={'/' + siteLanguage + '/' + item.path}
                        component={FwNavLink}
                        className={classes.menuButton}
                        activeClassName={classes.selectedMainNavLink}
                        key={index}>
                        {item.name}
                      </Button>
                    )
                  ) : null,
                )}
              </Toolbar>
            </AppBar>
          )
        } else {
          return (
            <React.Fragment>
              <AppBar position="fixed" className={classes.root}>
                <Toolbar>
                  <img src="/images/cest-logo-white.128.png" alt={siteNameFull} className={classes.CESTLogo} />
                  <Typography variant="h6" className={classes.title} component={NavLink} to="/">
                    {isWidthDown('sm', this.props.width) ? siteName : siteNameFull}
                  </Typography>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="Menu"
                    onClick={toggleDrawer(position, true)}>
                    <MenuIcon />
                  </IconButton>
                </Toolbar>
              </AppBar>
              <SwipeableDrawer
                anchor={position}
                className={classes.SwipeableDrawer}
                open={this.state[position]}
                onClose={toggleDrawer(position, false)}
                onOpen={toggleDrawer(position, true)}>
                {drawerList(position)}
              </SwipeableDrawer>
            </React.Fragment>
          )
        }
      }
    },
  ),
)
