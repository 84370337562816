import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import { Button } from '@material-ui/core'

import ImageIcon from '@material-ui/icons/Image'
import ListIcon from '@material-ui/icons/ListAlt'
import CalendarIcon from '@material-ui/icons/Event'
import EmailIcon from '@material-ui/icons/Drafts'
import LinkIcon from '@material-ui/icons/Link'
import ShareIcon from '@material-ui/icons/Share'
import CheckedIcon from '@material-ui/icons/CheckCircle'

import Webminarii from './ScoalaAltfelWebminarii'

const styles = (theme) => ({
  detailsPageH2: {
    marginTop: 24,
  },
  pageBanner: {
    width: '100%',
    margin: theme.spacing(0,0,3)
  },
  youTubeContainer: {
    position: 'relative',
    // width: '100%',
    width: '66%',
    height: 0,
    margin: '24px auto',
    // paddingTop: '56.25%',
    paddingTop: '43.94%',
  },
  youTubeFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  listItem: {
    color: theme.palette.text.primary,
  },
  listIcon: {
    // backgroundColor: theme.palette.text.primary,
    // backgroundColor: '#95c11fff',
    backgroundColor: 'rgba(255,255,255,.35)',
    // color: '#95c11fff',
    color: theme.palette.text.primary,
  },
  btnView: {
    height: 64,
    marginTop: 16,
  },
})

const lists = {
  desf: [
    {
      text: 'Consultați lista cu webinarii disponibile',
      icon: 'ListIcon',
    },
    {
      text:
        'Faceți maxim 2 programări prin completarea formularului pentru slotul orar disponibil',
      icon: 'CalendarIcon',
    },
    {
      text:
        'O confirmare automată de înscriere va fi trimisă pe adresa de email din formular și în maxim 1 zi răspunsul de  confirmare/neacceptare* a programării',
      icon: 'EmailIcon',
    },
    {
      text:
        'Conectarea participanților se face prin platforma Zoom',
      icon: 'LinkIcon',
    },
    {
      text: 'Profesorii au în sarcină distribuirea linkului către elevii participanți',
      icon: 'ShareIcon',
    },
  ],
  bdes: [
    'Programul SAlt-M se derulează pe perioada 25 mai - 5 iunie cu webinarii zilnice (L-V) de 1-1.5h',
    '<b>Un cadru didactic poate alege participarea la maxim 2 webinarii cu o clasă sau cu clase diferite</b>',
    'Potrivit pentru <b>elevii de gimnaziu și liceu</b>',
    '<b>Nr maxim de persoane (per webinar):</b> 2 clase sau echivalentul a 60 de elevi+profesor/i conectați online simultan',
    '<b>Pot programa un webinar</b> profesorii, managerii școlari sau părinții (doar cu acordul profesorilor)',
    'Programările se realizează pe principiul <b>primul sosit - primul servit</b>',
    'Profesorii vor primi <b>adeverință nominală de participare</b>',
  ],
}

const iconIterator = {
  ImageIcon: ImageIcon,
  ListIcon: ListIcon,
  CalendarIcon: CalendarIcon,
  EmailIcon: EmailIcon,
  LinkIcon: LinkIcon,
  ShareIcon: ShareIcon,
}

export default withStyles(styles)(
  class ScoalaAltfel extends React.Component {
    constructor(props) {
      super(props)
      this.state = {}
    }

    render() {
      const { classes } = this.props

      return (
        <div style={this.props.style ? this.props.style : ''}>
          <img src="/images/SAlt-M-banner.png" className={classes.pageBanner} alt="SAlt-M-banner" />
          <Typography gutterBottom>
            Săptămâna Școala Altfel este un program dedicat activităților extracurriculare și extrașcolare prin care se susține necesitatea unei educații alternative prin activități informale pentru elevi.
          </Typography>
          <Typography gutterBottom>
            De-a lungul ultimilor șapte ani, Școala Altfel a reușit să facă o punte între mediul formal și nonformal, susținută de legătura puternică dintre dorința de învățare și pasiunea de a împărtăși. Învățătorii și profesorii și-au văzut copiii iradiind de energia implicării în contexte de învățare inedite.
          </Typography>
          <Typography gutterBottom>
            Un astfel de cadru a fost oferit de institutele de cercetare de pe Platforma Măgurele, alături de facultăți din cadrul Universității București, în mod direct sau sub egida programelor inițiate de Comunitatea Educație pentru Știință.
          </Typography>
          <div className={classes.youTubeContainer}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/HzD_OHrDGD8"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={classes.youTubeFrame}
            />
          </div>
          <Typography gutterBottom>
            Prin programul Săptămâna Altfel la Măgurele (SAlt-M) continuăm să venim în ajutorul profesorilor și în întâmpinarea curiozității elevilor printr-un itinerar virtual, o aventură într-un univers al științei, al cercetării de frontieră și al descoperirilor uimitoare ale universului exterior, dar și interior (prin explorare, autocunoaștere și dezvoltare personală și a carierei). Acest periplu tematic contribuie, prin interacțiunea directă a cercetătorilor și consilierilor cu elevii, la prospectarea unor domenii interesante ale (auto)cunoașterii și totodată la consolidarea  cunoștințelor și abilităților printr-un program special potrivit vârstei și capacității de înțelegere a elevilor, pe care îl mutăm, provizoriu, și în mediul online.
          </Typography>
          <Typography gutterBottom>
            Încercăm, astfel, să demonstrăm că putem muta cadrul Învățării experențiale și acea energie pe care o dă Școala Altfel și în școala de zi cu zi.
          </Typography>
          <Typography gutterBottom>Să fie știință și evoluție prin educație!</Typography>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h4" component="h2" className={classes.detailsPageH2}>
                Desfășurare:
              </Typography>
              <Typography gutterBottom>Înscrierea la webinarii al unei clase se face de către un cadru didactic. Lecțiile sunt pentru nivel de gimnaziu sau de liceu.</Typography>
              <List className={classes.root}>
                {lists.desf.map((item, index) => {
                  const ListItemIcon = iconIterator[item.icon]
                  return (
                    <ListItem className={classes.listItem} key={index}>
                      <ListItemAvatar>
                        <Avatar className={classes.listIcon}>
                          <ListItemIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: item.text }} />} />
                    </ListItem>
                  )
                })}
              </List>
              {/* <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                className={classes.btnView}
                startIcon={<CalendarIcon />}
                href="https://cest.youcanbook.me/"
                target="_blank">
                CALENDAR ȘI ÎNREGISTRARE
              </Button> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography gutterBottom variant="h4" component="h2" className={classes.detailsPageH2}>
                Bine de știut:
              </Typography>
              <List className={classes.root}>
                {lists.bdes.map((item, index) => {
                  return (
                    <ListItem className={classes.listItem} key={index}>
                      <ListItemAvatar>
                        <Avatar className={classes.listIcon}>
                          <CheckedIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: item }} />} />
                    </ListItem>
                  )
                })}
              </List>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h3" component="h2" style={{marginBottom: 48}} align="center">
                Webinarii
              </Typography>
              <Webminarii />
            </Grid>
          </Grid>
        </div>
      )
    }
  }
)
