import Page from '../layout/Page'
import LeafletMap from '../layout/LeafletMap'

const componentList = {
  // LAYOUT
  Page: Page,
  // Maps
  CESTMap: LeafletMap,
  // CONTENT
  // Home: Home,
  // Locations: Locations,
  // WWAre: WWAre,
  // HWDIt: HWDIt,
  // Contact: Contact,
  // Footer: Footer,
  Home: Page,
  WWAre: Page,
  HWDIt: Page,
  Events: Page,
  Activities: Page,
  CalendarActivitati: Page,
  ScoalaAltfel: Page,
  Contact: Page,
  Footer: Page,
  Privacy: Page,
}

export default componentList
