import React from 'react'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography, Button, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import SdVTABS from './SdVTABS'
import SdVTABProfesori from './SdVTABProfesori'
import SdVTABElevi from './SdVTABElevi'
import ROSESchool from './ROSESchoolAutocomplete'

const useStyles = makeStyles(theme => ({
  imageContainer: {
    margin: '32px auto',
    textAlign: "center"
  },
  pageBanner: {
    width: '100%',
    margin: theme.spacing(0,0,3)
  },
  floatImage: {
    float: 'left',
    width: theme.spacing(32),
    marginRight: theme.spacing(6),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      width: theme.spacing(45.5),
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },

  },

  youTubeContainer: {
    position: 'relative',
    // width: '100%',
    width: '66%',
    height: 0,
    margin: '32px auto',
    // paddingTop: '56.25%',
    paddingTop: '43.94%',
  },
  youTubeFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },

  contentButton: {
    margin: theme.spacing(3,1,3,0)
  },

  GSHTypography: {
    marginBottom: theme.spacing(2),
    // '&>ul, &>ol': {
    //   paddingLeft: 0
    // },
    // '&>ul>li, &>ol>li': {
    //   paddingLeft: theme.spacing(5)
    // }
    '&>p': {
      margin: 0
    }
  }

}))

const Image = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.imageContainer}>
      <img src={props.children} className={classes.floatImage} alt={props.title} />
    </Box>
  )
}
const PageBanner = (props) => {
  const classes = useStyles()
  return (
    <Box className={classes.imageContainer}>
      <img src={props.children} className={classes.pageBanner} alt={props.title || props.children} />
    </Box>
  )
}

const YouTube = (src) => {
  const classes = useStyles()
  return (
    <Box className={classes.youTubeContainer}>
      {/* <img src={`/images/${src.children}.png`} className={classes.pageBanner} alt={src.children} /> */}
      <iframe
        width="560"
        height="315"
        src="https://www.youtube-nocookie.com/embed/HzD_OHrDGD8"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className={classes.youTubeFrame}
      />
    </Box>
  )
}

const MUIButton = (props) => {
  const classes = useStyles()
  console.log(props)
  return (
    <Button {...props.componentProps} href={props.href} className={clsx(classes.contentButton,props.className)}>{props.title}</Button>
  )
}

const ComboBox = ({data, props, label}) => {
  return (
    <Autocomplete
      {...props}
      options={data}
      getOptionLabel={(option) => option.title}
      // style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
    />
  );
}


const components = {
  Image: Image,
  YouTube: YouTube,
  Box: Box,
  Typography: Typography,
  Button: MUIButton,
  PageBanner: PageBanner,
  ComboBox: ComboBox,
  SdVTABS: SdVTABS,
  SdVTABElevi: SdVTABElevi,
  SdVTABProfesori: SdVTABProfesori,
  ROSESchool: ROSESchool
};

export default components;