import React from 'react'
import PropTypes from 'prop-types'
import { withGoogleSheets } from 'react-db-google-sheets'
// import moment from 'moment'
// import 'moment/locale/ro'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Box, Card, CardContent, CardActions, Typography, Button, Chip } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import EventBusyIcon from '@material-ui/icons/EventBusy'

// import "./styles.css";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  card: {
    position: 'relative',
    marginBottom: 24,
    padding: 16,
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    // border: "1px solid #ccc",

    '&.hasLink': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    '&.hasLink:hover': {
      backgroundColor: 'white',
    },
    '&.hasLink,&.hasLink *': {
      cursor: 'pointer',
    },
    '& *': {
      cursor: 'default',
    },
    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: 'Crimson',
      color: 'white',
      opacity: 0.65,
    },
  },
  cardTitle: {
    marginTop: 0,
  },
  cardImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 0,
    paddingTop: '100%',
    borderRadius: '50%',
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    width: 'auto',
    height: '100%',
  },
  hasIcon: {
    paddingLeft: 128,
  },
  blockPic: {
    position: 'absolute',
    top: 24,
    left: 24,
    width: 110,
    height: 110,
    overflow: 'hidden',
    border: '2px solid #ebebeb',
    borderRadius: '50%',

    '&>img': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      '-webkit-transform': 'translateX(-50%) translateY(-50%)',
      '-ms-transform': 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',
      maxWidth: 'none',
      maxHeight: '100%',
    },
  },
  extraData: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  chip: {
    margin: theme.spacing(0, 0.25, 0.5),
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  chipDomenii: {
    margin: theme.spacing(0, 0.25, 0.5),
    backgroundColor: '#777',
    color: 'white',
    // textTransform: "capitalize"
  },
}))

function App(props) {
  // console.log(props);
  const classes = useStyles()

  const openYCBM = url => {
    // console.log(url);
    window.open(url, '_blank')
  }

  // const dataSource = props.db.Sheet1;
  const dataSource = props.db.Sheet1.sort((a, b) => {
    // console.log(parseInt(a.ID, a.FullyBooked, 10), parseInt(b.FullyBooked, 10));
    return parseInt(a.FullyBooked, 10) - parseInt(b.FullyBooked, 10)
  })

  return (
    <Container maxWidth="md">
      {dataSource &&
        dataSource.map((data, index) => {
          const nivel = data.Nivel && data.Nivel.replace(/\s/g, '').split(',')
          const domenii = data.Domenii && data.Domenii.split(',')
          return (
            <Card
              elevation={data.FullyBooked === '0' ? 6 : 1}
              className={`${data.URL && data.FullyBooked === '0' ? classes.card + ' hasLink' : classes.card}`}
              onClick={data.FullyBooked !== '0' ? null : () => openYCBM(data.URL)}>
              <CardContent>
                <Grid container spacing={3}>
                  {data.Icon && (
                    <Grid item xs={7} sm={5} md={3} style={{ margin: '0 auto' }}>
                      <Box className={classes.cardImageContainer}>
                        <img alt="" src={data.Icon} className={classes.cardImage} />
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12} md={data.Icon ? 9 : 12} className={classes.extraData}>
                    {/* {data.Dată && (
                    <Typography
                      variant="caption"
                      className={`${classes.title} dateTime`}
                      color="textSecondary"
                    >
                      <span>
                        {moment(data.Dată).format("dddd, DD MMMM YYYY")
                        // .locale("ro")
                        }
                      </span>
                      {data.Oră && <span>, {data.Oră}</span>}
                    </Typography>
                  )} */}
                    <Typography variant="body2" component="p" gutterBottom>
                      {data.Nivel &&
                        nivel.map((item, index) => {
                          return <Chip color="primary" size="small" label={item} key={index} className={classes.chip} />
                        })}
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom className={classes.cardTitle}>
                      {/* {"Webinar "} */}
                      {/* {data.ID} */}
                      {/* {". "} */}
                      {data.Titlu}
                    </Typography>
                    <Typography variant="body2" component="p" gutterBottom>
                      {data.Abstract}
                    </Typography>
                    {(data.Nume || data.Instituție) && (
                      <Typography
                        // align="right"
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom>
                        {data.Nume && (
                          <React.Fragment>
                            {/* {data.Profil ? (
                          <Button
                            href={data.Profil}
                            target="_blank"
                            rel="noopener noreferrer"
                            size="small"
                          >
                            <b>{data.Nume}</b>
                          </Button>
                        ) : ( */}
                            <b>{data.Nume}</b>
                            {/* )} */}
                          </React.Fragment>
                        )}
                        {data.Instituție && <span>, {data.Instituție}</span>}
                      </Typography>
                    )}
                    {data.Domenii && (
                      <Typography variant="caption">
                        Domenii:{' '}
                        {domenii.map((item, index) => {
                          return <Chip size="small" label={item} key={index} className={classes.chipDomenii} />
                        })}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container spacing={3}>
                  <Grid item xs={12} align="right" className={classes.extraData}>
                    {/* {data.Nivel &&
                      nivel.map((item, index) => {
                        return (
                          <Chip
                            color="secondary"
                            size="small"
                            label={item}
                            key={index}
                            className={classes.chip}
                          />
                        );
                      })} */}
                    {data.URL && (
                      <Button
                        color="secondary"
                        variant="contained"
                        size={data.FullyBooked !== '0' ? 'medium' : 'large'}
                        disabled={data.FullyBooked !== '0' ? true : false}
                        startIcon={data.FullyBooked !== '0' ? <EventBusyIcon /> : <EventIcon />}>
                        {data.FullyBooked !== '0' ? 'Toate sloturile rezervate' : 'ÎNREGISTRARE'}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          )
        })}
    </Container>
  )
}

App.propTypes = {
  db: PropTypes.shape({
    sheet1: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default withGoogleSheets('Sheet1')(App)
