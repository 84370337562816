import React from 'react'
import PropTypes from 'prop-types'
// import ReactMarkdown from 'react-markdown'

import { makeStyles } from '@material-ui/core/styles'
import { Box, AppBar, Tabs, Tab, Typography } from '@material-ui/core'

import components from './components'

// import Profesori from './SdVTABProfesori'
// import Elevi from './SdVTABElevi'

const useStyles = makeStyles(theme => ({
  tabsBar: {
    marginTop: theme.spacing(6),
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  tabs: {
    borderBottom: `${theme.palette.secondary.main} 3px solid`,

    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.tertiary.main
    }
  },
  tabTitle: {
    padding: theme.spacing(4,2),
    textTransform: 'none',

    '&.Mui-selected': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      // borderRadius: theme.shape.borderRadius,
      borderRadius: theme.spacing(1,1,0,0),
      borderRadiusTopLeft: theme.shape.borderRadius
    }
  },
  tabPanel: {
    borderBottom: `${theme.palette.secondary.main} 3px solid`,
  }
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const SdVTABS = (props) => {
  const classes = useStyles()
  const { title, tabs } = props

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // console.log(props)

  return (
    <Box>
      <AppBar position="static" color="default" component="div" className={classes.tabsBar}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label={title}
          className={classes.tabs}
        >
          {
            tabs && tabs.map((tab,index) => <Tab label={<Typography variant="h4">{tab.title}</Typography>} {...a11yProps(index)} className={classes.tabTitle} key={index} />)
          }
          {/* <Tab label={<Typography variant="h4">Pentru elevii de liceu</Typography>} {...a11yProps(0)} className={classes.tabTitle} />
          <Tab label={<Typography variant="h4">Pentru profesori STEM și profesori consilieri școlari</Typography>} {...a11yProps(1)} className={classes.tabTitle} /> */}
        </Tabs>
      </AppBar>
      {
        tabs && tabs.map((tab,index) => {
          const RenderComponent = components[tab.component]
          return (
            <TabPanel value={value} index={index} className={classes.tabPanel} key={index}><RenderComponent /></TabPanel>
          )
        })
      }
      {/* <TabPanel value={value} index={0} className={classes.tabPanel}>
        <Elevi />
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
        <Profesori />
      </TabPanel> */}
    </Box>
  )
};

SdVTABS.propTypes = {
  title: PropTypes.string
}

SdVTABS.defaultProps = {
  title: 'CES Tabs Component'
}

export default SdVTABS