import React from 'react'

import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/ro' // new

import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import { Typography, AppBar, Toolbar, Tooltip, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ShortTextIcon from '@material-ui/icons/ShortText';
import ScheduleIcon from '@material-ui/icons/Schedule';
import MapPinIcon from '@material-ui/icons/Room';

import './calendar/styles.css'

import googleAPI from '../utils/GoogleCalendarAPI'

const calendar_configuration = {
  api_key: 'AIzaSyBBNIKQcTCE0SYWMPJ6RGxnVnGxdLBQYfg',
  calendars: [
    {
      name: 'Activități CEST',
      url: 'educatiepentrustiinta.ro_0ffa957raudhuumuflnudfshuo@group.calendar.google.com',
    },
  ],
  dailyRecurrence: 700,
  weeklyRecurrence: 500,
  monthlyRecurrence: 20,
}

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

// const now = new Date()
// const myEventsList = [
//   {
//     id: 1,
//     title: 'Sample Multi-day Event #001',
//     start: new Date(2019, 7, 13, 19, 30, 0),
//     end: new Date(2019, 7, 30, 2, 0, 0),
//     allDay: true,
//   },
//   {
//     id: 2,
//     title: 'Event on 14th',
//     start: new Date(2019, 7, 14, 9, 30, 0),
//     end: new Date(2019, 7, 14, 12, 0, 0),
//   },
//   {
//     id: 3,
//     title: 'Event Today',
//     start: new Date(new Date().setHours(new Date().getHours() - 3)),
//     end: new Date(new Date().setHours(new Date().getHours() + 3)),
//   },
//   {
//     id: 4,
//     title: 'Point in Time Event',
//     start: now,
//     end: new Date(new Date().setHours(new Date().getHours() + 8)),
//   },
// ]

const calendarTranslations = {
  date: ' Dată',
  time: 'Oră',
  event: 'Activitate',
  allDay: 'Întreaga zi',
  week: 'Săptămână',
  work_week: 'Zile lucrătoare',
  day: 'Zi',
  month: 'Lună',
  previous: ' « ', // Înapoi
  next: ' » ', // Înainte
  yesterday: 'Ieri',
  tomorrow: 'Mâine',
  today: 'Azi',
  agenda: 'Agendă',
  noEventsInRange: 'Nu există activități programate în această perioadă.',
  showMore: total => `+ încă ${total} activități`,
}

const styles = theme => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white',
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1]
  },
  title: {
    flex: 1,
    margin: theme.spacing(0,2,0,1),
    cursor: 'pointer',
    color: theme.palette.secondary.main
  },
  dialogContent: {
    '& h1.MuiTypography-root': {
      lineHeight: 1.2
    },
    [theme.breakpoints.down('md')]: {
      '& h1.MuiTypography-root': {
        fontSize: '2.8rem'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& h1.MuiTypography-root': {
        marginBottom: '1rem',
        fontSize: '2rem',
      }
    },
  },
  calendarDetailsListItem: {
    color: theme.palette.text.primary,

    '& .MuiListItemAvatar-root': {
      alignSelf: 'flex-start',
      minWidth: theme.spacing(6)
    },
    '& .MuiAvatar-root': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    }
  },
  date: {
    textTransform: 'capitalize'
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  avatarImage: {
    width: 24
  },
  btnGMeet: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontWeight: '300'
  }

})

export default withWidth()(
  withStyles(styles)(
    class MyCalendar extends React.Component {

      constructor(props) {
        super(props)
        this.state = {
          events: [],
          currentEvent: {},
          'snackOpened': false,
          minTime: null,
          maxTime: null
        }
      }

      componentDidMount = () => {
        if (calendar_configuration) {
          this.getGoogleCalendarEvents()
        } else {
          console.log('Google Calendar API requires you pass a configuration object')
        }
      }

      getGoogleCalendarEvents = () => {
        googleAPI
        .getAllCalendars(calendar_configuration)
        .then(events => {
          this.setState({ events })
          // console.log(events)
        })
        .catch(err => {
          throw new Error(err)
        })
      }

      urlify(text) {
          var urlRegex = /(https?:\/\/[^\s]+)/g;
          return text.replace(urlRegex, function(url) {
              return '<a href="' + url + '" target="_blank" style="display:block;">' + url + '</a>';
          })
          // or alternatively
          // return text.replace(urlRegex, '<a href="$1">$1</a>')
      }

      render() {
        const {classes} = this.props
        const showGoogleCalendarEventDetails = (event) => {
          this.setState({'currentEvent':event});
          this.setState({'snackOpened':true});
        }
        const handleClose = () => {
          this.setState({'snackOpened':false});
        };
        const handleClick = (url) => {
          window.open(url,'_blank')
        };

        const minDate = new Date('August 19, 1975 09:00:00');
        const maxDate = new Date('August 19, 1975 18:00:00');
        const minTime = moment(minDate).format('HH:mm:ss');
        const maxTime = moment(maxDate).format('HH:mm:ss');
        // console.log(minTime, maxTime)

        // console.log(Views)

        return (
          <React.Fragment>
            <Calendar
              localizer={localizer}
              // events={myEventsList}
              events={this.state.events}
              startAccessor="start"
              endAccessor="end"
              showMultiDayTimes={true}
              views={[Views.WEEK, Views.MONTH, Views.AGENDA]}
              defaultView={Views.MONTH}
              min={minDate}
              max={maxDate}
              popup={true}
              popupOffset={{ x: 30, y: -20 }}
              onSelectEvent={function(event) {
                console.log(event);
                showGoogleCalendarEventDetails(event)
              }}
              culture="ro-ro"
              messages={calendarTranslations}
            />
            <Dialog
              hideBackdrop={true}
              fullScreen={isWidthUp('sm', this.props.width) ? false : true}
              // key={key}
              {...this.props}
              open={this.state.snackOpened}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <AppBar color='secondary' className={classes.appBar}>
                <Toolbar>
                    <ScheduleIcon />
                    <Typography
                      variant="h6"
                      onClick={() => handleClick(this.state.currentEvent.glink)}
                      noWrap
                      title={this.state.currentEvent.title}
                      className={classes.title}
                    >
                      {this.state.currentEvent.title}
                    </Typography>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                    <CloseIcon />
                  </IconButton>
                </Toolbar>
              </AppBar> 

              {/* <DialogTitle style={{display:'flex'}}>
                <div onClick={handleClick(this.state.currentEvent.glink)} className={classes.title}>
                  <Typography variant="h5" component="span">{this.state.currentEvent.title}</Typography>
                </div>
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </DialogTitle> */}
              <DialogContent className={classes.dialogContent}>
                <List>
                  <ListItem className={classes.calendarDetailsListItem}>
                    <ListItemAvatar>
                      <Avatar>
                        <ScheduleIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        moment(this.state.currentEvent.start).format('Do MMMM YYYY') === moment(this.state.currentEvent.end).format('Do MMMM YYYY')
                          ? moment(this.state.currentEvent.start).format('dddd, Do MMMM YYYY')
                          : moment(this.state.currentEvent.start).format('dddd, Do MMMM YYYY, HH:mm')
                      }
                      secondary={this.state.currentEvent.end ? <span>{moment(this.state.currentEvent.start).format('HH:mm')} - {moment(this.state.currentEvent.end).format('HH:mm')}</span> : moment(this.state.currentEvent.start).format('HH:mm')}
                      className={classes.date}
                    />
                  </ListItem>
                  {this.state.currentEvent.description &&
                    <ListItem className={classes.calendarDetailsListItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <ShortTextIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: this.urlify(this.state.currentEvent.description) }} />} />
                    </ListItem>
                  }
                  {this.state.currentEvent.meta && this.state.currentEvent.meta.conferenceData &&
                    <ListItem className={classes.calendarDetailsListItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <img src={this.state.currentEvent.meta.conferenceData.conferenceSolution.iconUri} className={classes.avatarImage} />
                        </Avatar>
                      </ListItemAvatar>
                    <ListItemText
                      primary={this.state.currentEvent.meta.conferenceData.name}
                      secondary={<Button className={classes.btnGMeet} href={`https://meet.google.com/${this.state.currentEvent.meta.conferenceData.conferenceId}`}>{`https://meet.google.com/${this.state.currentEvent.meta.conferenceData.conferenceId}`}</Button>}
                    />
                    </ListItem>
                  }
                  {this.state.currentEvent.location &&
                    <ListItem className={classes.calendarDetailsListItem}>
                      <ListItemAvatar>
                        <Avatar>
                          <MapPinIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={<span dangerouslySetInnerHTML={{ __html: this.urlify(this.state.currentEvent.location) }} />} />
                    </ListItem>
                  }
                </List>
                {/* <DialogContentText id="alert-dialog-slide-description"
                  // dangerouslySetInnerHTML={{ __html: JSON.stringify(this.state.currentEvent) }}
                /> */}
              </DialogContent>
              {/* <DialogActions>
                <Button onClick={handleClose} size={isWidthUp('sm', this.props.width) ? 'large' : 'small'} color="secondary">
                  Înapoi la site-ul Comunității
                </Button>
                <div style={{flexGrow:1}} />
                <Button onClick={handleVisit} size={isWidthUp('sm', this.props.width) ? 'large' : 'small'} variant='contained' style={{boxShadow:'none',backgroundColor:"#673ab7",color:'white'}}>
                  Vizitează pagina SAlt-M 2020
                </Button>
              </DialogActions> */}
            </Dialog>
          </React.Fragment>
        )
      }
    }
  )
)
