export const siteStructure = [
  {
    name: 'Acasă',
    path: '',
    toNext: 'cine-suntem',
    type: 'CESTMap',
    component: 'Home',
    slide: true,
    menu: true,
  },
  {
    name: 'Cine suntem',
    path: 'cine-suntem',
    toNext: 'ce-facem',
    type: 'Page',
    component: 'WWAre',
    slide: true,
    menu: true,
  },
  {
    name: 'Ce facem',
    path: 'ce-facem',
    toNext: 'locatii',
    type: 'Page',
    component: 'HWDIt',
    slide: true,
    menu: true,
  },
  {
    name: 'Evenimente',
    path: 'evenimente',
    toNext: 'activitati',
    type: 'Page',
    component: 'Events',
    slide: true,
    menu: true,
  },
  {
    name: 'Școala de Vară',
    path: 'scoala-de-vara',
    toNext: 'evenimente',
    type: 'Page',
    component: 'ScoalaDeVara',
    slide: false,
    menu: false,
  },
  {
    name: 'Activități',
    path: 'activitati',
    toNext: 'calendar-activitati',
    type: 'Page',
    component: 'Activitati',
    slide: true,
    menu: true,
  },
  {
    name: 'Calendar activități',
    path: 'calendar-activitati',
    toNext: 'activitati',
    type: 'Page',
    component: 'CalendarActivitati',
    slide: false,
    menu: false,
  },
  {
    name: 'Școala Altfel',
    // path: 'activitati/2020/scoala-altfel',
    path: 'scoala-altfel',
    toNext: 'activitati',
    type: 'Page',
    component: 'ScoalaAltfel',
    slide: false,
    menu: false,
  },
  {
    name: 'Ce pregătim',
    path: 'ce-pregatim',
    toNext: 'contact',
    type: 'Page',
    component: 'CePregatim',
    slide: true,
    menu: true,
  },
  // {
  //   name: 'Contact',
  //   path: 'contact',
  //   toNext: '',
  //   type: 'Page',
  //   component: 'Contact',
  //   slide: true,
  //   menu: true,
  // },
  // {
  //   name: 'Politica de confidențialitate',
  //   path: 'politica-de-confidentialitate',
  //   toNext: 'contact',
  //   type: 'Page',
  //   component: 'Privacy',
  //   slide: false,
  //   menu: false,
  // },
]
