import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { withGoogleSheets } from 'react-db-google-sheets'
// import moment from 'moment'
// import 'moment/locale/ro'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Grid, Box, Card, CardContent, CardActions, Typography, Button, Chip } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import EventBusyIcon from '@material-ui/icons/EventBusy'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'

import ImageIcon from '@material-ui/icons/Image'
import ListIcon from '@material-ui/icons/ListAlt'
import CalendarIcon from '@material-ui/icons/Event'
import EmailIcon from '@material-ui/icons/Drafts'
import LinkIcon from '@material-ui/icons/Link'
import ShareIcon from '@material-ui/icons/Share'
import CheckedIcon from '@material-ui/icons/CheckCircle'

import components from './components'

// import "./styles.css";

const useStyles = makeStyles(theme => ({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  card: {
    position: 'relative',
    marginBottom: 24,
    padding: 16,
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    // border: "1px solid #ccc",

    '&.hasLink': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
    '&.hasLink:hover': {
      backgroundColor: 'white',
    },
    '&.hasLink,&.hasLink *': {
      cursor: 'pointer',
    },
    '& *': {
      cursor: 'default',
    },
    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: 'Crimson',
      color: 'white',
      opacity: 0.65,
    },
  },
  cardTitle: {
    marginTop: 0,
  },
  cardImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 0,
    paddingTop: '100%',
    borderRadius: '50%',
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    width: 'auto',
    height: '100%',
  },
  hasIcon: {
    paddingLeft: 128,
  },
  blockPic: {
    position: 'absolute',
    top: 24,
    left: 24,
    width: 110,
    height: 110,
    overflow: 'hidden',
    border: '2px solid #ebebeb',
    borderRadius: '50%',

    '&>img': {
      display: 'block',
      position: 'absolute',
      top: '50%',
      left: '50%',
      '-webkit-transform': 'translateX(-50%) translateY(-50%)',
      '-ms-transform': 'translateX(-50%) translateY(-50%)',
      transform: 'translateX(-50%) translateY(-50%)',
      maxWidth: 'none',
      maxHeight: '100%',
    },
  },
  extraData: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  chip: {
    margin: theme.spacing(0, 0.25, 0.5),
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  chipDomenii: {
    margin: theme.spacing(0, 0.25, 0.5),
    backgroundColor: '#777',
    color: 'white',
    // textTransform: "capitalize"
  },

  sectionTitle: {
    margin: theme.spacing(6,0,3)
  },

  listItem: {
    color: theme.palette.text.primary,
  },
  listIcon: {
    // backgroundColor: theme.palette.text.primary,
    // backgroundColor: '#95c11fff',
    backgroundColor: 'rgba(255,255,255,.35)',
    // color: '#95c11fff',
    color: theme.palette.text.primary,
  },

}))

const lists = {
  desf: [
    {
      text: 'Consultați lista cu webinarii disponibile',
      icon: 'ListIcon',
    },
    {
      text:
        'Faceți maxim 2 programări prin completarea formularului pentru slotul orar disponibil',
      icon: 'CalendarIcon',
    },
    {
      text:
        'O confirmare automată de înscriere va fi trimisă pe adresa de email din formular și în maxim 1 zi răspunsul de  confirmare/neacceptare* a programării',
      icon: 'EmailIcon',
    },
    {
      text:
        'Conectarea participanților se face prin platforma Zoom',
      icon: 'LinkIcon',
    },
    {
      text: 'Profesorii au în sarcină distribuirea linkului către elevii participanți',
      icon: 'ShareIcon',
    },
  ],
  bdes: [
    'Programul SAlt-M se derulează pe perioada 25 mai - 5 iunie cu webinarii zilnice (L-V) de 1-1.5h',
    '<b>Un cadru didactic poate alege participarea la maxim 2 webinarii cu o clasă sau cu clase diferite</b>',
    'Potrivit pentru <b>elevii de gimnaziu și liceu</b>',
    '<b>Nr maxim de persoane (per webinar):</b> 2 clase sau echivalentul a 60 de elevi+profesor/i conectați online simultan',
    '<b>Pot programa un webinar</b> profesorii, managerii școlari sau părinții (doar cu acordul profesorilor)',
    'Programările se realizează pe principiul <b>primul sosit - primul servit</b>',
    'Profesorii vor primi <b>adeverință nominală de participare</b>',
  ],
}

const iconIterator = {
  ImageIcon: ImageIcon,
  ListIcon: ListIcon,
  CalendarIcon: CalendarIcon,
  EmailIcon: EmailIcon,
  LinkIcon: LinkIcon,
  ShareIcon: ShareIcon,
}

function ScoalaDeVara(props) {
  // console.log(props);
  const classes = useStyles()

  const openYCBM = url => {
    // console.log(url);
    window.open(url, '_blank')
  }

  // console.log(props)
  
  const dataSource = props.db.ScoalaDeVara;
  // const dataSource = props.db.Sheet1.sort((a, b) => {
  //   // console.log(parseInt(a.ID, a.FullyBooked, 10), parseInt(b.FullyBooked, 10));
  //   return parseInt(a.FullyBooked, 10) - parseInt(b.FullyBooked, 10)
  // })

  const testProps = {
    gutterBottom: true, align: 'center'
  }

  // const contentType = (key, props) => {
  //   const RenderComponent = components[data.component]
  //   let contentType 
  //   switch (key) {
  //     case 'Box'
  //       return
  //   }
  //   return
  // }

  return (
    <Container maxWidth={false}>
      {dataSource &&
        dataSource.map((data, index) => {
          const RenderComponent = components[data.type]
          const getProps = {
            ...JSON.parse(data.props),
            // ...testProps
          }
          if (JSON.parse(data.display)) {
            return (
              <React.Fragment key={index}>
                {data.title && data.type !== 'Button' && data.type !== 'Image' && data.type !== 'PageBanner' && <Typography variant="h4" {...getProps} className={classes.sectionTitle}>{data.title}</Typography>}
                {/* <p><code>display: {JSON.parse(data.display).toString()} </code></p> */}
                {/* <p><code>component: {data.component} </code>, <code>props: {data.props} </code></p> */}
                {data.content && (
                  data.type === 'Button'
                    ? (
                      <RenderComponent
                        componentProps={getProps}
                        href={data.content}
                        title={data.title}
                        className={classes[`GSH${data.type}`]}
                      >
                        {data.title}
                      </RenderComponent>
                    )
                    : (
                      <RenderComponent
                        {...getProps}
                        component={data.component}
                        title={data.title && data.title}
                        className={classes[`GSH${data.type}`]}
                      >
                        {
                          data.type === 'Typography' || data.type === 'Box'
                            ? <ReactMarkdown source={data.content} />
                            : data.content}
                      </RenderComponent>
                    )
                )}
              </React.Fragment>
            );
          }
          return null
        })}
    </Container>
  )
}

ScoalaDeVara.propTypes = {
  db: PropTypes.shape({
    ScoalaDeVara: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default withGoogleSheets('ScoalaDeVara')(ScoalaDeVara)
