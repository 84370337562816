// 82388D
// 50066A
// F39200
// 95C11F

const Home = {
  // background: 'linear-gradient(0deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.2x.png) left top',
  pageTitle: 'Comunitatea Educație pentru Științe',
  pageContent: [
    {
      component: 'CESTMap',
      props: {
        showdialog: 1,
        maxWidth: 'lg',
        fullWidth: true,
        content: {
          // text:
          //   '<h1 class="MuiTypography-root MuiTypography-h3 MuiTypography-gutterBottom MuiTypography-alignCenter">Școala de vară de Știință și Tehnologie de la Măgurele, Ediția Online 2020, <br/> 23 august - 5 septembrie</h1><img src="https://msciteh.educatiepentrustiinta.ro/images/MSciTeh-2020-banner.png" class="dialog-banner" /><h2 class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom MuiTypography-alignCenter" style="font-family: &quot;Bree Serif&quot;, Roboto, Helvetica, Arial, sans-serif;">Experiențe de învățare online, dezbateri, tururi virtuale, kituri hands-on, proiecte și lucru în echipă și un focus pe contribuțiile științei la cunoașterea și prezervarea patrimoniului natural și cultural.</h2>',
          text:
            '<iframe src="https://scicommtoolbox.ro/?pID=005d48582f" style="height:70vh;border:none" width="100%" />',
        },
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h4', gutterBottom: true, align: 'center', style: { marginTop: 20 } },
      content: {
        text: 'Comunitatea Educație pentru Științe',
      },
    },
    {
      component: 'Typography',
      props: { align: 'center' },
      content: { text: 'Platformă de cercetare-dezvoltare-inovare în educația STEM și carierele viitorului' },
    },
  ],
}

const WWAre = {
  pageTitle: 'Cine suntem',
  // background: 'linear-gradient(180deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  //
  pageContent: [
    // {
    //   component: 'Snackbar',
    //   props: {style:{backgroundColor:'#ffc107'}},
    //   content: {
    //     text: 'blabla ... ',
    //   },
    // },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft img256 noShadow' },
      content: {
        text: '<img src="/images/cest-logo-white.tag.512.png"/>',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Inițiativa de a crea o comunitate profesională în domeniul educației pentru științe, cunoscut și sub acronimul de STEM (știință, technologie, inginerie și matematică), a apărut prin conectarea unui număr de proiecte și inițiative care se desfășoară în Universitatea din București și institutele de pe platforma de educație și cercetare de la Măgurele, în special Institutul de Fizică și Inginerie Nucleară - Horia Hulubei și Institutul Național pentru Fizica Pământului.',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Obiectivul asumat este acela de a dezvolta o platformă pentru schimbul de experiență și dialog, pentru a facilita învățarea reciprocă, pentru toți actorii care practică STEM, subliniind tendințele și răspunsurile recente, provocările și orizonturile noi care să susțină corelarea ofertei educaționale a Universității din București cu evoluția pieței forței de muncă conectată în special domeniilor STEM.',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h2', gutterBottom: true, align: 'center' },
      content: {
        text: 'Cine ne susține',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage  img256 noShadow' },
      content: {
        text:
          '<img src="/images/content/cine-ne-sustine/Logo_Fundatia_Comunitara_Bucuresti.svg"/> <img src="/images/content/cine-ne-sustine/Logo-IKEA.svg"/>',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'noPadding CMSArticleTitle' },
      content: {
        text:
          'Proiect susținut prin Fondul IKEA pentru Educație și Dezvoltare, finanțat de IKEA România și gestionat de Fundația Comunitară București',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'https://fundatiacomunitarabucuresti.ro/proiect/fondul-ikea-pentru-educatie-si-dezvoltare/',
        target: '_blank',
        className: 'moreButtons',
        style: { marginBottom: 16 },
      },
      content: { text: 'Fondul IKEA pentru Educație și Dezvoltare' },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
  ],
}

const HWDIt = {
  pageTitle: 'Ce facem',
  // background: 'linear-gradient(0deg, #F39200 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageContent: [
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text:
          'Dezvoltăm parteneriate strategice între institutele de pe platformă și Universitatea din București pentru asumarea și implementarea unor inițiative de impact printre care:',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          '<ul><li>Desfășurarea de activități în domeniul educației pentru elevi, profesori și studenți, precum și pentru publicul larg</li><li>Dezvoltarea și participarea în proiecte naționale și europene</li><li>Coordonarea de rețele de profesori și activități în școli (Scientix, Roeduseis)</li><li>Dezvoltarea de parteneriate cu ONG-uri, administrație locală, asociații profesionale</li></ul>',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft' },
      content: {
        text: '<img src="/images/content/STEM-Learning-Ecosystem.png"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Dezvoltarea contextelor de educație inovativă care promovează:',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          '<ol><li>oportunități de învățare hands-on și creație directă, facilitate și prin intermediul tehnologiilor emergente dedicate prioritar elevilor, dar și studenților, </li><li>formarea-dezvoltarea profesională a profesorilor STEM și a consilierilor școlari și </li><li>cercetarea în domeniul pedagogiilor inovative și tehnologiilor educaționale.</li></ol>',
      },
    },
    // {
    //   component: 'Typography',
    //   props: { variant: 'h5', gutterBottom: true },
    //   content: {
    //     text: 'Obiectivele centrului se plasează pe axa educație – cercetare – inovare:',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, className: 'noBullet' },
    //   content: {
    //     text:
    //       '<ul><li>Susținerea transferului de cunoaștere de la nivelul activitățile de cercetare de pe platforma Măgurele și din laboratoarele UB către practicile educaționale. </li><li>Organizarea de activități de promovare și comunicare a științei – adresând misiunea de responsabilizare socială; </li><li>Dezvoltarea carierelor în domenii corelate STEM prin consolidarea serviciilor de suport în domeniul consilierii.</li></ul>',
    //   },
    // },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
  ],
}

const Events = {
  pageTitle: 'Evenimente',
  // background: 'linear-gradient(180deg, #F39200 0%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageContent: [
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/evenimente/CEST-NC.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle', style: { marginTop: 20 } },
      content: {
        text: 'Noaptea Cercetătorilor, București <br><small>27 Noiembrie 2020</small>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h6', gutterBottom: false, className: 'CMSArticleText' },
      content: {
        text:
          'Cercetarea nu intră în izolare în 2020!',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Eveniment anual organizat simultan la nivel european în care cercetătorii invită publicul larg să pășească în universul științific cu scopul de a populariza știința și învățarea distractivă.',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'https://scicommtoolbox.ro/?pID=005d48582f&evt=1',
        target: '_blank',
        style: { marginBottom: 16 },
      },
      content: { text: 'Activități 2020' },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'http://noapteacercetatorilor.ro/',
        target: '_blank',
        style: { marginBottom: 16 },
      },
      content: { text: 'noapteacercetatorilor.ro' },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/evenimente/CEST-CMCMPU.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle', style: { marginTop: 20 } },
      content: {
        text: 'Cu mic cu mare... prin Univers, Măgurele <br><small>Noiembrie 2020</small>',
      },
    },
    {
      component: 'Typography',
      props: {
        gutterBottom: true,
        className: 'moreButtons tmpFixFloatToBtns CMSArticleText',
      },
      content: {
        text:
          'Târgul propune prelegeri despre subiecte provocatoare, actuale, pe care știința este chemată să le soluționeze, dar și despre istoria științei și a platformei, vizite la infrastructurile de cercetare, discuții și întâlniri cu cercetători, prezentarea ofertelor de stagii de practică și a temelor de cercetare pentru studenți, experimente hands-on, ateliere, expoziții de carte etc.',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'http://events.theory.nipne.ro/prinunivers/',
        target: '_blank',
        className: 'moreButtons tmpFixFloatToBtns',
        style: { marginBottom: 16 },
      },
      content: { text: 'Ediția 2020' },
    },
    // {
    //   component: 'Button',
    //   props: {
    //     size: 'large',
    //     color: 'primary',
    //     variant: 'contained',
    //     href: 'http://events.theory.nipne.ro/prinunivers/2018/',
    //     target: '_blank',
    //     style: { marginBottom: 16 },
    //   },
    //   content: { text: 'Ediția 2018' },
    // },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/evenimente/CEST-SdV.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Școala de vară de Știință și Tehnologie de la Măgurele - online <br><small>Ediția 2020, 23 august - 5 septembrie</small>',
      },
    },
    {
      component: 'Typography',
      props: {
        gutterBottom: true,
        className: 'moreButtons tmpFixFloatToBtns CMSArticleText',
      },
      content: {
        text:
          'Eveniment anual ce propune trei programe educaționale corelate, ce oferă elevilor interesați de științe și tehnologie oportunități pentru a se familiariza cu domenii atractive și relevante (știință, inginerie, robotică, tehnologii, inteligență artificială), de a experimenta și a practica cercetarea științifică, lucrând la propriile proiecte de cercetare sub îndrumarea mentorilor în laboratoare; profesorilor oportunitatea de a-și dezvolta orizontul profesional de specialitate cu teme actuale și relevante pentru disciplinele predate, de a experimenta alternative pentru desfășurarea activității didactice, precum și a avea acces la resurse ce pot fi utilizate în activitatea la clasă cu elevii; profesorilor consilieri școlari oportunitatea de a-și dezvolta competențele relaționate orientării și consilierii pentru carieră, de a se plasa într-un context mai amplu al abilităților necesare pentru carierele viitorului.',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'http://events.theory.nipne.ro/msciteh/2018/',
        target: '_blank',
        className: 'moreButtons tmpFixFloatToBtns',
        style: { marginBottom: 16 },
      },
      content: { text: 'Şcoala de Vară 2018' },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'http://events.theory.nipne.ro/msciteh/',
        target: '_blank',
        style: { marginBottom: 16 },
      },
      content: { text: 'Şcoala de Vară 2019' },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'secondary',
        variant: 'contained',
        href: 'https://msciteh.educatiepentrustiinta.ro/',
        target: '_blank',
        style: { marginBottom: 16 },
      },
      content: { text: 'Şcoala de Vară 2020' },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/evenimente/CEST-CNCEST.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle', style: { marginTop: 20 } },
      content: {
        text:
          'Conferința Națională a <br>Comunității Educație pentru Știință, București <br><small>6-8 Decembrie 2019</small>',
      },
    },
    {
      component: 'Typography',
      props: {
        gutterBottom: true,
        className: 'moreButtons tmpFixFloatToBtns CMSArticleText',
      },
      content: {
        text:
          'Eveniment ce adună laolaltă experți din domeniul educațional de la nivel internațional și local, reprezentanți ai școlilor și universităților, ai institutelor de cercetare, factori de decizie la nivel de politici educaționale, industrie, ONG-uri, companii care vor prezenta, discuta și experimenta abordări inovative ale STEM, precum și tendințe de dezvoltare și provocări cu care se confruntă diverși actori interesați de dezvoltarea Comunității Educație pentru Științe.',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'https://cnces.educatiepentrustiinta.ro/',
        target: '_blank',
        className: 'moreButtons tmpFixFloatToBtns',
        style: { marginBottom: 16 },
      },
      content: { text: 'CNCES 2019' },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        href: 'https://cnces2017.unibuc.ro/',
        target: '_blank',
        style: { marginBottom: 16 },
      },
      content: { text: 'CNCES 2017' },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/evenimente/CEST-TEDx.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle', style: { marginTop: 20 } },
      content: {
        text: 'TEDxCERN@IFIN-HH, Măgurele',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'TEDx este un program de evenimente locale, organizate independent, care aduc oamenii împreună pentru a lua parte la experienţa TED. În cadrul unui eveniment TEDx, vorbitorii şi materialele video îndeamnă la discuţii profunde şi la interconectarea membrilor auditoriului.',
      },
    },
    // {
    //   component: 'Button',
    //   props: {
    //     size: 'large',
    //     color: 'primary',
    //     variant: 'contained',
    //     href: '',
    //     target: '_blank',
    //     style: { marginBottom: 16 },
    //   },
    //   content: { text: '{LINK}' },
    // },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
  ],
}

const ScoalaDeVara = {
  // background: 'linear-gradient(0deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageTitle: 'Școala de vară de Știință și Tehnologie de la Măgurele',
  pageContent: [
    {
      component: 'ScoalaDeVara',
      props: {
        style: { textAlign: 'left' },
      },
      content: {},
    },
    {
      component: 'Typography',
      props: { variant: 'h3', gutterBottom: true },
      content: {
        text: ' ',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        to: '/ro/evenimente',
        component: 'Link',
      },
      content: { text: 'Înapoi' },
    },
  ],
}

const Activitati = {
  // background: 'linear-gradient(0deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageTitle: 'Activități',
  pageContent: [
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        to: '/ro/calendar-activitati',
        component: 'Link',
        className: 'buttonLeftIcon calendarActivitati',
      },
      content: {
        icon: { type: 'MUI', ID: 'Calendar' },
        text: 'Calendar activități',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/activitati/CEST-ACT-ScA.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Activități Școala Altfel Măgurele (SAlt-M)',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Activități susținute de către cercetători pe subiecte din diverse domenii STEM (ex: fizică, geoștiințe, chimie, biologie, inginerie, etc) în combinație și/sau utilizând metode și instrumente din activități educaționale dovedite a avea maxim impact asupra grupurilor de elevi',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        // type: 'route',
        href: ' scoala-altfel',
        target: '_self',
        style: { marginBottom: 16 },
      },
      content: { text: 'Școala Altfel Măgurele - SAlt-M 2020' },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/activitati/CEST-ACT-ROBO.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Robotică',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Cursuri de robotică și programare bazate pe LEGO WeDo 2.0 și LEGO Mindstorms EV3. Pregatire și mentorat pentru competiții specifice. Conectarea eleviilor cu experți în domenii variate pentru pregătirea proiectelor de cercetare și a roboților.',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/activitati/CEST-ACT-CCESP.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Activități consiliere în carieră elevi, studenți și profesori',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Programul va susține elevii să-și cunoască potențialul și resursele, să-și formeze o viziune despre propria carieră și le va susține dezvoltarea competențelor necesare pe piața muncii și îi va ajuta pe termen lung să ia decizii optime legate de propria carieră. În acest scop, programul de consiliere și orientare profesională cuprinde două subactivități complementare – una de informare și conștientizare adresate elevilor, a doua centrată pe elemente concrete de consiliere și orientare profesională desfășurate în grup.',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Box',
      props: { className: 'CMSArticleImage imgLeft imgRound img256' },
      content: {
        text: '<img src="/images/content/activitati/CEST-ACT-CoDo.jpg"/>',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Ateliere SCRACH și Coder Dojo',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Activități menite să ofere copiilor interesați contextul în care să poată învăța programare într-un mediu informal, prin sesiuni regulate, în afara programului școlar',
      },
    },
  ],
}

const CalendarActivitati = {
  // background: 'linear-gradient(0deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageTitle: 'Calendar activități',
  pageContent: [
    { component: 'CalendarActivitati', props: {}, content: {} },
    {
      component: 'Typography',
      props: { variant: 'h3', gutterBottom: true },
      content: {
        text: ' ',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        to: '/ro/activitati',
        component: 'Link',
      },
      content: { text: 'Înapoi' },
    },
  ],
}

const ScoalaAltfel = {
  // background: 'linear-gradient(0deg, #82388D 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageTitle: 'Școala Altfel Măgurele 2020',
  pageContent: [
    {
      component: 'ScoalaAltfel',
      props: {
        style: { textAlign: 'left' },
      },
      content: {},
    },
    {
      component: 'Typography',
      props: { variant: 'h3', gutterBottom: true },
      content: {
        text: ' ',
      },
    },
    {
      component: 'Button',
      props: {
        size: 'large',
        color: 'primary',
        variant: 'contained',
        to: '/ro/activitati',
        component: 'Link',
      },
      content: { text: 'Înapoi' },
    },
  ],
}

const CePregatim = {
  pageTitle: 'Ce pregătim',
  // background: 'linear-gradient(0deg, #F39200 25%, #50066A 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageContent: [
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Dezvoltarea unui centru organizat ca spații (experiențe) inovative de învățare în linie cu rezultatele cercetării în domeniul științelor educației, psihologiei, neuroștiințelor, cu dezvoltarea tehnologiilor și perspectivele de dezvoltare în domeniul AI, precum și cu concluziile practicilor în domeniu',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          'Dezvoltarea şi consolidarea unui nucleu sinergic de acţiune (cercetare, formare, dezvoltare de politici educaţionale) format din experţi în domeniul ştiinţelor educaţiei, psihologiei şi al educației STEM, precum și cercetători interesați de transferul de cunoaștere, constituindu-se în esență într-un hub de învățare-cercetare-inovare',
      },
    },
    {
      component: 'Typography',
      props: { variant: 'h5', gutterBottom: true, className: 'CMSArticleTitle' },
      content: {
        text: 'Obiectivele centrului se plasează pe axa educație – cercetare – inovare:',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, className: 'CMSArticleText' },
      content: {
        text:
          '<ul><li>Cercetare educațională</li><li>Formare inițială și continuă cadre didactice care predau discipline STEM și consilieri școlari</li><li>Activități educaționale formale și non-formale, recurente și periodice cu elevi</li><li>Dezvoltare programe, proiecte, activități cu parteneri din institute de cercetare, ONG-uri etc.</li></ul>',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'CustomTempGrid' },
      content: {
        text:
          '<div class="GridParent"><div class="GridRow"><div class="GridItem"><p class="MuiTypography-root CMSArticleImage imgLeft imgRound img256 MuiTypography-body1 MuiTypography-gutterBottom cePregatim"><img src="/images/content/ce-pregatim/CEST-spatii-imersive.png"></p><h5 class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom">Spații imersive de învățare (VR, AR etc.)</h5></div><div class="GridItem"><p class="MuiTypography-root CMSArticleImage imgLeft imgRound img256 MuiTypography-body1 MuiTypography-gutterBottom cePregatim"><img src="/images/content/ce-pregatim/CEST-clasa-viitorului.png"></p><h5 class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom">Clasa viitorului</h5></div></div><div class="GridRow"><div class="GridItem"><p class="MuiTypography-root CMSArticleImage imgLeft imgRound img256 MuiTypography-body1 MuiTypography-gutterBottom cePregatim"><img src="/images/content/ce-pregatim/CEST-stem-makerspace.png"></p><h5 class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom">STEM Makerspace</h5></div><div class="GridItem"><h5 class="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom">Spații dedicate platformei Măgurele</h5><p class="MuiTypography-root MuiTypography-body1 MuiTypography-gutterBottom"><ul><li>Expoziții</li><li>Spațiu de întâlnire - spațiu experimental - un muzeu miniatural</li><li>Punct de informare privind activitatea platformei</li></ul></p></div></div></div>',
      },
    },
    {
      component: 'Typography',
      props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
      content: {
        text: '&nbsp;',
      },
    },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, className: 'CMSArticleImage imgLeft imgRound img256' },
    //   content: {
    //     text: '<img src="/images/content/ce-pregatim/CEST-spatii-imersive.png"/>',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { variant: 'h5', gutterBottom: true },
    //   content: {
    //     text: 'Spații imersive de învățare (VR, AR etc.)',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
    //   content: {
    //     text: '&nbsp;',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, className: 'CMSArticleImage imgLeft imgRound img256' },
    //   content: {
    //     text: '<img src="/images/content/ce-pregatim/CEST-clasa-viitorului.png"/>',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { variant: 'h5', gutterBottom: true },
    //   content: {
    //     text: 'Clasa viitorului',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
    //   content: {
    //     text: '&nbsp;',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { variant: 'h5', gutterBottom: true },
    //   content: {
    //     text: 'Spații dedicate platformei Măgurele',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true },
    //   content: {
    //     text:
    //       '<ul><li>Expoziții</li><li>Spațiu de întâlnire - spațiu experimental - un muzeu miniatural</li><li>Punct de informare privind activitatea platformei</li></ul>',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
    //   content: {
    //     text: '&nbsp;',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, className: 'CMSArticleImage imgLeft imgRound img256' },
    //   content: {
    //     text: '<img src="/images/content/ce-pregatim/CEST-stem-makerspace.png"/>',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { variant: 'h5', gutterBottom: true },
    //   content: {
    //     text: 'STEM Makerspace',
    //   },
    // },
    // {
    //   component: 'Typography',
    //   props: { gutterBottom: true, component: 'div', className: 'ArticleListSeparator' },
    //   content: {
    //     text: '&nbsp;',
    //   },
    // },
  ],
}

const Contact = {
  pageTitle: 'Contactează-ne',
  // background: 'linear-gradient(180deg, #50066A 0%, #82388D 100%)',
  background: 'transparent url(/images/cest-pattern.png) left top',
  pageContent: [
    {
      component: 'Typography',
      props: {},
      content: {
        text: '<strong>contact</strong> info<p> </p>',
      },
    },
    // {
    //   component: 'Typography',
    //   props: {
    //     variant: 'caption',
    //     component: 'p',
    //     // align: 'center',
    //     // style: {position: 'absolute', bottom: '16px'}
    //   },
    //   content: {
    //     text: 'Copyright &copy; <strong>Comunitatea Educație pentru Științe</strong>. Toate drepturile rezervate.<br>',
    //   },
    // },
    // {
    //   component: 'Button',
    //   props: {
    //     size: 'small',
    //     color: 'inherit',
    //     variant: 'text',
    //     to: '/ro/politica-de-confidentialitate',
    //     component: 'Link',
    //   },
    //   content: { text: 'Politica de confidențialitate' },
    // },
  ],
}

const Privacy = {
  // pageTitle: 'Privacy Policy',
  pageTitle: 'Politica de confidențialitate',
  // background: 'linear-gradient(180deg, #82388D 0%, #50066A 100%)',
  background: '#95C11F url(/images/cest-pattern.png) left top',
  pageContent: [
    // {
    //   component: 'Typography',
    //   props: { variant: 'h2', gutterBottom: true },
    //   content: { text: 'Privacy Policy' },
    // },
    { component: 'Typography', props: { variant: 'body2', gutterBottom: true }, content: { text: 'text politică' } },
    { component: 'Typography', props: { variant: 'h2', gutterBottom: true }, content: { text: 'Cookie Policy' } },
    {
      component: 'Typography',
      props: { variant: 'body2', gutterBottom: true },
      content: { text: 'text cookie policy' },
    },
  ],
}

export { Home, WWAre, HWDIt, Events, ScoalaDeVara, Activitati, CalendarActivitati, ScoalaAltfel, CePregatim, Contact, Privacy }
