import React from 'react'
// import { Link } from 'react-router-dom'
import coreComponentList from '../core/CoreComponentIterator'
import Container from '@material-ui/core/Container'

export default class Map extends React.Component {
  render() {
    const {
      // next,
      content,
      // siteLanguage
    } = this.props
    const pageContent = content.pageContent
    // console.log(this.props)
    return (
      <div className="simple-trans-slide" style={{ background: `${content.background}` }}>
        {pageContent.map((item, key) => {
          const ComponentIterator = coreComponentList[item.component]
          const itemProps = item.props
          if (item.component === 'CESTMap') {
            return <ComponentIterator key={key} {...itemProps} />
          } else {
            const itemContent = item.content.text
            if (item.component === 'Typography') {
              return (
                <Container key={key}>
                  <ComponentIterator {...itemProps} dangerouslySetInnerHTML={{ __html: itemContent }} />
                </Container>
              )
            } else {
              return (
                <Container key={key}>
                  <ComponentIterator {...itemProps} component={coreComponentList[itemProps.component]}>
                    {itemContent}
                  </ComponentIterator>
                </Container>
              )
            }
          }
        })}
      </div>
    )
  }
}
