import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { withGoogleSheets } from 'react-db-google-sheets'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Box, Typography } from '@material-ui/core'

import components from './components'

const useStyles = makeStyles(theme => ({
  GSHTypography: {
    marginBottom: theme.spacing(2),
    // '&>ul, &>ol': {
    //   paddingLeft: 0
    // },
    // '&>ul>li, &>ol>li': {
    //   paddingLeft: theme.spacing(5)
    // }
    '&>p': {
      margin: 0
    }
  },
  GSHButton: {
    height: 56
  }
}))

const SdVTABProfesori = (props) => {
  const classes = useStyles()
  const dataSource = props.db.SdVTABProfesori;

  return (
    <Box>
      {dataSource &&
        dataSource.map((data, index) => {
          const RenderComponent = components[data.type]
          const getProps = {
            ...JSON.parse(data.props),
            // ...testProps
          }
          if (JSON.parse(data.display)) {
            return (
              <React.Fragment key={index}>
                {data.title && data.type !== 'Button' && data.type !== 'Image' && data.type !== 'PageBanner' && <Typography variant="h4" {...getProps} className={classes.sectionTitle}>{data.title}</Typography>}
                {/* <p><code>display: {JSON.parse(data.display).toString()} </code></p> */}
                {/* <p><code>component: {data.component} </code>, <code>props: {data.props} </code></p> */}
                {data.content && (
                  data.type === 'Button'
                    ? (
                      <RenderComponent
                        componentProps={getProps}
                        href={data.content}
                        title={data.title}
                        className={classes[`GSH${data.type}`]}
                      >
                        {data.title}
                      </RenderComponent>
                    )
                    : (
                      <RenderComponent
                        {...getProps}
                        component={data.component}
                        {...data.title && `title=${data.title}`}
                        className={classes[`GSH${data.type}`]}
                      >
                        {
                          data.type === 'Typography' || data.type === 'Box'
                            ? <ReactMarkdown source={data.content} />
                            : data.content}
                      </RenderComponent>
                    )
                )}
              </React.Fragment>
            );
          }
          return null
        })}
    </Box>
  )
};

SdVTABProfesori.propTypes = {
  db: PropTypes.shape({
    SdVTABProfesori: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default withGoogleSheets('SdVTABProfesori')(SdVTABProfesori)