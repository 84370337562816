import React from 'react'

import { withStyles } from '@material-ui/core/styles'
// import Typography from '@material-ui/core/Typography'


import ScoalaDeVaraContent from './ScoalaDeVaraContent'

const styles = (theme) => ({
  detailsPageH2: {
    marginTop: 24,
  },

  btnView: {
    height: 64,
    marginTop: 16,
  },
})

export default withStyles(styles)(
  class ScoalaAltfel extends React.Component {
    constructor(props) {
      super(props)
      this.state = {}
    }

    render() {
      // const { classes } = this.props

      return (
        <div style={this.props.style ? this.props.style : ''}>
          <ScoalaDeVaraContent />
        </div>
      )
    }
  }
)
