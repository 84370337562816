import React from 'react'
import { Link } from 'react-router-dom'
import coreComponentList from '../core/CoreComponentIterator'
import { withStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
// import { mergeClasses } from '@material-ui/styles'
import clsx from 'clsx'

// import Icon from '@mdi/react'
// import { mdiAccount } from '@mdi/js'

import Calendar from '@material-ui/icons/CalendarToday'
import { Box } from '@material-ui/core'

import SnackbarContent from '@material-ui/core/SnackbarContent'
import Slide from '@material-ui/core/Slide'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'

import Button from '@material-ui/core/Button'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

const Typography = coreComponentList['Typography']

const styles = (theme) => ({
  PageTitle: {
    fontSize: '5.5rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '4rem',
      marginBottom: '5rem',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '3rem',
      marginBottom: '4rem',
      fontSize: '4.5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3rem',
    },
  },
  AnotherPageTitle: {
    // color: 'pink',
  },
  buttonLeftIcon: {
    paddingLeft: theme.spacing(2),
    '&>span>svg': {
      marginRight: theme.spacing(1.5),
    },
  },
  buttonRightIcon: {
    paddingRight: theme.spacing(2),
    '&>span>svg': {
      marginLeft: theme.spacing(1.5),
    },
  },
  buttonIconSmall: {
    fontSize: 20,
  },
  btnLeftIcon: {
    marginRight: theme.spacing(1),
  },
})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />
}

function TransitionUp(props) {
  return <Slide {...props} direction="up" />
}

function TransitionRight(props) {
  return <Slide {...props} direction="right" />
}

function TransitionDown(props) {
  return <Slide {...props} direction="down" />
}

export default withStyles(styles)(
  class Page extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        snackOpened: true,
        transition: TransitionDown,
      }
    }
    render() {
      const {
        next,
        content,
        // siteLanguage,
        classes,
      } = this.props
      // console.log(next)
      const pageContent = content.pageContent
      // console.log(Object.keys(pageContent))

      const handleClose = () => {
        this.setState({ snackOpened: false })
      }
      const handleVisit = () => {
        window.location.href = 'https://cnces.educatiepentrustiinta.ro/ro/'
      }

      return (
        <div className="simple-trans-slide" style={{ background: `${content.background}` }}>
          <div className="App-container">
            <div className="slide-content">
              {/* <Link to={next} component="div" /> */}
              <div className="slide-content-body">
                <Container>
                  <Typography variant="h1" gutterBottom className={clsx(classes.PageTitle, classes.AnotherPageTitle)}>
                    {content.pageTitle}
                  </Typography>
                  <Box className="contentContainer">
                    {pageContent.map((item, key) => {
                      const ComponentIterator = coreComponentList[item.component]
                      if (item.component === 'Typography') {
                        return (
                          <ComponentIterator
                            key={key}
                            {...item.props}
                            dangerouslySetInnerHTML={{ __html: item.content.text }}
                          />
                        )
                      } else if (item.component === 'Snackbar') {
                        return (
                          <ComponentIterator
                            key={key}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                            }}
                            open={this.state.snackOpened}
                            onClose={this.handleClose}
                            TransitionComponent={this.state.transition}
                            ContentProps={{
                              'aria-describedby': 'message-id',
                            }}>
                            <SnackbarContent
                              {...item.props}
                              onClose={this.handleClose}
                              message={
                                <span>
                                  <InfoIcon />
                                  <span id="message-id" dangerouslySetInnerHTML={{ __html: item.content.text }} />
                                </span>
                              }
                              // action={[
                              //   <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose}>
                              //     <CloseIcon className={classes.icon} />
                              //   </IconButton>,
                              // ]}
                            />
                          </ComponentIterator>
                        )
                      } else if (item.component === 'Dialog') {
                        return (
                          <ComponentIterator
                            key={key}
                            {...item.props}
                            open={this.state.snackOpened}
                            // TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description">
                            <DialogContent>
                              <DialogContentText
                                id="alert-dialog-slide-description"
                                dangerouslySetInnerHTML={{ __html: item.content.text }}
                              />
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleClose} size="large" color="secondary">
                                Înapoi la site-ul Comunității
                              </Button>
                              <div style={{ flexGrow: 1 }} />
                              <Button
                                onClick={handleVisit}
                                size="large"
                                variant="contained"
                                style={{ boxShadow: 'none', backgroundColor: '#673ab7', color: 'white' }}>
                                Vizitează site-ul CNCES 2019
                              </Button>
                            </DialogActions>
                          </ComponentIterator>
                        )
                      } else if (item.component === 'Box') {
                        return (
                          <ComponentIterator
                            key={key}
                            {...item.props}
                            dangerouslySetInnerHTML={{ __html: item.content.text }}
                          />
                        )
                      } else if (item.component === 'Button') {
                        return (
                          <ComponentIterator
                            key={key}
                            {...item.props}
                            component={coreComponentList[item.props.component]}
                            // className={item.props.className ? classes[item.props.className] : ''}
                          >
                            {item.content.icon ? <Calendar className={classes.btnLeftIcon} /> : ''}
                            {/* {item.content.icon ? ' ' : ''} */}
                            {item.content.text}
                          </ComponentIterator>
                        )
                      } else {
                        return (
                          <ComponentIterator
                            key={key}
                            {...item.props}
                            component={coreComponentList[item.props.component]}>
                            {item.content.text}
                          </ComponentIterator>
                        )
                      }
                    })}
                  </Box>
                </Container>
              </div>
            </div>
          </div>
        </div>
      )
    }
  },
)
