import Dialog from '@material-ui/core/Dialog'
import Snackbar from '@material-ui/core/Snackbar'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import CESTMap from '../content/CEST-Map'
// import CalendarActivitati from '../content/CalendarActivitati'
import CalendarActivitati from '../content/Calendar-RBC'
import ScoalaAltfel from '../content/ScoalaAltfel'
import ScoalaDeVara from '../content/ScoalaDeVara'

const componentList = {
  // Router
  Link: Link,
  // MUI
  Dialog: Dialog,
  Snackbar: Snackbar,
  Box: Box,
  Typography: Typography,
  Button: Button,
  // Leaflet
  CESTMap: CESTMap,
  CalendarActivitati: CalendarActivitati,
  ScoalaAltfel: ScoalaAltfel,
  ScoalaDeVara: ScoalaDeVara,
}

export default componentList
